.mapboxgl-map {
    font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
    overflow: hidden;
    position: relative;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.mapboxgl-canvas {
    position: absolute;
    left: 0;
    top: 0;
}

.mapboxgl-map:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

.mapboxgl-canary {
    background-color: salmon;
}

.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
    cursor: grab;
    -webkit-user-select: none;
    user-select: none;
}

.mapboxgl-canvas-container.mapboxgl-interactive.mapboxgl-track-pointer {
    cursor: pointer;
}

.mapboxgl-canvas-container.mapboxgl-interactive:active,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass:active {
    cursor: grabbing;
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate .mapboxgl-canvas {
    touch-action: pan-x pan-y;
}

.mapboxgl-canvas-container.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-drag-pan .mapboxgl-canvas {
    touch-action: pinch-zoom;
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan .mapboxgl-canvas {
    touch-action: none;
}

.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right { position: absolute; pointer-events: none; z-index: 2; }
.mapboxgl-ctrl-top-left     { top: 0; left: 0; }
.mapboxgl-ctrl-top-right    { top: 0; right: 0; }
.mapboxgl-ctrl-bottom-left  { bottom: 0; left: 0; }
.mapboxgl-ctrl-bottom-right { right: 0; bottom: 0; }

.mapboxgl-ctrl {
    clear: both;
    pointer-events: auto;

    /* workaround for a Safari bug https://github.com/mapbox/mapbox-gl-js/issues/8185 */
    transform: translate(0, 0);
}
.mapboxgl-ctrl-top-left .mapboxgl-ctrl     { margin: 10px 0 0 10px; float: left; }
.mapboxgl-ctrl-top-right .mapboxgl-ctrl    { margin: 10px 10px 0 0; float: right; }
.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl  { margin: 0 0 10px 10px; float: left; }
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl { margin: 0 10px 10px 0; float: right; }

.mapboxgl-ctrl-group {
    border-radius: 4px;
    background: #fff;
}

.mapboxgl-ctrl-group:not(:empty) {
    box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
}

@media (-ms-high-contrast: active) {
    .mapboxgl-ctrl-group:not(:empty) {
        box-shadow: 0 0 0 2px ButtonText;
    }
}

.mapboxgl-ctrl-group button {
    width: 29px;
    height: 29px;
    display: block;
    padding: 0;
    outline: none;
    border: 0;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
}

.mapboxgl-ctrl-group button + button {
    border-top: 1px solid #ddd;
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

@media (-ms-high-contrast: active) {
    .mapboxgl-ctrl-icon {
        background-color: transparent;
    }

    .mapboxgl-ctrl-group button + button {
        border-top: 1px solid ButtonText;
    }
}

.mapboxgl-ctrl-attrib-button:focus,
.mapboxgl-ctrl-group button:focus {
    box-shadow: 0 0 2px 2px rgb(0 150 255 / 100%);
}

.mapboxgl-ctrl button:disabled {
    cursor: not-allowed;
}

.mapboxgl-ctrl button:disabled .mapboxgl-ctrl-icon {
    opacity: 0.25;
}

.mapboxgl-ctrl-group button:first-child {
    border-radius: 4px 4px 0 0;
}

.mapboxgl-ctrl-group button:last-child {
    border-radius: 0 0 4px 4px;
}

.mapboxgl-ctrl-group button:only-child {
    border-radius: inherit;
}

.mapboxgl-ctrl button:not(:disabled):hover {
    background-color: rgb(0 0 0 / 5%);
}

.mapboxgl-ctrl-group button:focus:focus-visible {
    box-shadow: 0 0 2px 2px rgb(0 150 255 / 100%);
}

.mapboxgl-ctrl-group button:focus:not(:focus-visible) {
    box-shadow: none;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: svg-load("svg/mapboxgl-ctrl-zoom-out.svg", fill: #333);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: svg-load("svg/mapboxgl-ctrl-zoom-in.svg", fill: #333);
}

@media (-ms-high-contrast: active) {
    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
        background-image: svg-load("svg/mapboxgl-ctrl-zoom-out.svg", fill: #fff);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
        background-image: svg-load("svg/mapboxgl-ctrl-zoom-in.svg", fill: #fff);
    }
}

@media (-ms-high-contrast: black-on-white) {
    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
        background-image: svg-load("svg/mapboxgl-ctrl-zoom-out.svg", fill: #000);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
        background-image: svg-load("svg/mapboxgl-ctrl-zoom-in.svg", fill: #000);
    }
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
    background-image: svg-load("svg/mapboxgl-ctrl-fullscreen.svg", fill: #333);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
    background-image: svg-load("svg/mapboxgl-ctrl-shrink.svg");
}

@media (-ms-high-contrast: active) {
    .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
        background-image: svg-load("svg/mapboxgl-ctrl-fullscreen.svg", fill: #fff);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
        background-image: svg-load("svg/mapboxgl-ctrl-shrink.svg", fill: #fff);
    }
}

@media (-ms-high-contrast: black-on-white) {
    .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
        background-image: svg-load("svg/mapboxgl-ctrl-fullscreen.svg", fill: #000);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
        background-image: svg-load("svg/mapboxgl-ctrl-shrink.svg", fill: #000);
    }
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
    background-image: svg-load("svg/mapboxgl-ctrl-compass.svg", fill: #333);
}

@media (-ms-high-contrast: active) {
    .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
        @svg-load ctrl-compass-white url("svg/mapboxgl-ctrl-compass.svg") {
            fill: #fff;
            #south { fill: #999; }
        }

        background-image: svg-inline(ctrl-compass-white);
    }
}

@media (-ms-high-contrast: black-on-white) {
    .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
        background-image: svg-load("svg/mapboxgl-ctrl-compass.svg", fill: #000);
    }
}

@svg-load ctrl-geolocate url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #333;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-white url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #fff;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-black url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #000;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-disabled url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #aaa;
    #stroke { fill: #f00; }
}

@svg-load ctrl-geolocate-disabled-white url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #999;
    #stroke { fill: #f00; }
}

@svg-load ctrl-geolocate-disabled-black url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #666;
    #stroke { fill: #f00; }
}

@svg-load ctrl-geolocate-active url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #33b5e5;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-active-error url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #e58978;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-background url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #33b5e5;
    #stroke { display: none; }
    #dot { display: none; }
}

@svg-load ctrl-geolocate-background-error url("svg/mapboxgl-ctrl-geolocate.svg") {
    fill: #e54e33;
    #stroke { display: none; }
    #dot { display: none; }
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate:disabled .mapboxgl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-disabled);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active .mapboxgl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-active);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active-error .mapboxgl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-active-error);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background .mapboxgl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-background);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background-error .mapboxgl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-background-error);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-waiting .mapboxgl-ctrl-icon {
    animation: mapboxgl-spin 2s infinite linear;
}

@media (-ms-high-contrast: active) {
    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-white);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate:disabled .mapboxgl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-disabled-white);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active .mapboxgl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-active);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active-error .mapboxgl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-active-error);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background .mapboxgl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-background);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background-error .mapboxgl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-background-error);
    }
}

@media (-ms-high-contrast: black-on-white) {
    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-black);
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate:disabled .mapboxgl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-disabled-black);
    }
}

@keyframes mapboxgl-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

a.mapboxgl-ctrl-logo {
    width: 88px;
    height: 23px;
    margin: 0 0 -4px -4px;
    display: block;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    background-image: svg-load("svg/mapboxgl-ctrl-logo.svg");
}

a.mapboxgl-ctrl-logo.mapboxgl-compact {
    width: 23px;
}

@media (-ms-high-contrast: active) {
    a.mapboxgl-ctrl-logo {
        @svg-load ctrl-logo-white url("svg/mapboxgl-ctrl-logo.svg") {
            #outline { opacity: 1; }
            #fill { opacity: 1; }
        }

        background-color: transparent;
        background-image: svg-inline(ctrl-logo-white);
    }
}

@media (-ms-high-contrast: black-on-white) {
    a.mapboxgl-ctrl-logo {
        @svg-load ctrl-logo-black url("svg/mapboxgl-ctrl-logo.svg") {
            #outline { opacity: 1; fill: #fff; stroke: #fff; }
            #fill { opacity: 1; fill: #000; }
        }

        background-image: svg-inline(ctrl-logo-black);
    }
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    padding: 0 5px;
    background-color: rgb(255 255 255 / 50%);
    margin: 0;
}

@media screen {
    .mapboxgl-ctrl-attrib.mapboxgl-compact {
        min-height: 20px;
        padding: 2px 24px 2px 0;
        margin: 10px;
        position: relative;
        background-color: #fff;
        border-radius: 12px;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact-show {
        padding: 2px 28px 2px 8px;
        visibility: visible;
    }

    .mapboxgl-ctrl-top-left > .mapboxgl-ctrl-attrib.mapboxgl-compact-show,
    .mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl-attrib.mapboxgl-compact-show {
        padding: 2px 8px 2px 28px;
        border-radius: 12px;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-attrib-inner {
        display: none;
    }

    .mapboxgl-ctrl-attrib-button {
        display: none;
        cursor: pointer;
        position: absolute;
        background-image: svg-load("svg/mapboxgl-ctrl-attrib.svg");
        background-color: rgb(255 255 255 / 50%);
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border-radius: 12px;
        outline: none;
        top: 0;
        right: 0;
        border: 0;
    }

    .mapboxgl-ctrl-top-left .mapboxgl-ctrl-attrib-button,
    .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-attrib-button {
        left: 0;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-attrib-button,
    .mapboxgl-ctrl-attrib.mapboxgl-compact-show .mapboxgl-ctrl-attrib-inner {
        display: block;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact-show .mapboxgl-ctrl-attrib-button {
        background-color: rgb(0 0 0 / 5%);
    }

    .mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        bottom: 0;
        right: 0;
    }

    .mapboxgl-ctrl-top-right > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        top: 0;
        right: 0;
    }

    .mapboxgl-ctrl-top-left > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        top: 0;
        left: 0;
    }

    .mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        bottom: 0;
        left: 0;
    }
}

@media screen and (-ms-high-contrast: active) {
    .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        background-image: svg-load("svg/mapboxgl-ctrl-attrib.svg", fill=#fff);
    }
}

@media screen and (-ms-high-contrast: black-on-white) {
    .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        background-image: svg-load("svg/mapboxgl-ctrl-attrib.svg");
    }
}

.mapboxgl-ctrl-attrib a {
    color: rgb(0 0 0 / 75%);
    text-decoration: none;
}

.mapboxgl-ctrl-attrib a:hover {
    color: inherit;
    text-decoration: underline;
}

/* stylelint-disable-next-line selector-class-pattern */
.mapboxgl-ctrl-attrib .mapbox-improve-map {
    font-weight: bold;
    margin-left: 2px;
}

.mapboxgl-attrib-empty {
    display: none;
}

.mapboxgl-ctrl-scale {
    background-color: rgb(255 255 255 / 75%);
    font-size: 10px;
    border-width: medium 2px 2px;
    border-style: none solid solid;
    border-color: #333;
    padding: 0 5px;
    color: #333;
    box-sizing: border-box;
    white-space: nowrap;
}

.mapboxgl-popup {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    will-change: transform;
    pointer-events: none;
}

.mapboxgl-popup-anchor-top,
.mapboxgl-popup-anchor-top-left,
.mapboxgl-popup-anchor-top-right {
    flex-direction: column;
}

.mapboxgl-popup-anchor-bottom,
.mapboxgl-popup-anchor-bottom-left,
.mapboxgl-popup-anchor-bottom-right {
    flex-direction: column-reverse;
}

.mapboxgl-popup-anchor-left {
    flex-direction: row;
}

.mapboxgl-popup-anchor-right {
    flex-direction: row-reverse;
}

.mapboxgl-popup-tip {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    z-index: 1;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    align-self: center;
    border-top: none;
    border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    align-self: flex-start;
    border-top: none;
    border-left: none;
    border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    align-self: flex-end;
    border-top: none;
    border-right: none;
    border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    align-self: center;
    border-bottom: none;
    border-top-color: #fff;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    align-self: flex-start;
    border-bottom: none;
    border-left: none;
    border-top-color: #fff;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    align-self: flex-end;
    border-bottom: none;
    border-right: none;
    border-top-color: #fff;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    align-self: center;
    border-left: none;
    border-right-color: #fff;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    align-self: center;
    border-right: none;
    border-left-color: #fff;
}

.mapboxgl-popup-close-button {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    border-radius: 0 3px 0 0;
    cursor: pointer;
    background-color: transparent;
}

.mapboxgl-popup-close-button:hover {
    background-color: rgb(0 0 0 / 5%);
}

.mapboxgl-popup-content {
    position: relative;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    padding: 10px 10px 15px;
    pointer-events: auto;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
    border-top-left-radius: 0;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
    border-top-right-radius: 0;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
    border-bottom-left-radius: 0;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
    border-bottom-right-radius: 0;
}

.mapboxgl-popup-track-pointer {
    display: none;
}

.mapboxgl-popup-track-pointer * {
    pointer-events: none;
    user-select: none;
}

.mapboxgl-map:hover .mapboxgl-popup-track-pointer {
    display: flex;
}

.mapboxgl-map:active .mapboxgl-popup-track-pointer {
    display: none;
}

.mapboxgl-marker {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    opacity: 1;
    transition: opacity 0.2s;
}

.mapboxgl-user-location-dot {
    background-color: #1da1f2;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.mapboxgl-user-location-dot::before {
    background-color: #1da1f2;
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    animation: mapboxgl-user-location-dot-pulse 2s infinite;
}

.mapboxgl-user-location-dot::after {
    border-radius: 50%;
    border: 2px solid #fff;
    content: "";
    height: 19px;
    left: -2px;
    position: absolute;
    top: -2px;
    width: 19px;
    box-sizing: border-box;
    box-shadow: 0 0 3px rgb(0 0 0 / 35%);
}

.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading {
    width: 0;
    height: 0;
}

.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading::before,
.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading::after {
    content: "";
    border-bottom: 7.5px solid #4aa1eb;
    position: absolute;
}

.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading::before {
    border-left: 7.5px solid transparent;
    transform: translate(0, -28px) skewY(-20deg);
}

.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading::after {
    border-right: 7.5px solid transparent;
    transform: translate(7.5px, -28px) skewY(20deg);
}

@keyframes mapboxgl-user-location-dot-pulse {
    0%   { transform: scale(1); opacity: 1; }
    70%  { transform: scale(3); opacity: 0; }
    100% { transform: scale(1); opacity: 0; }
}

.mapboxgl-user-location-dot-stale {
    background-color: #aaa;
}

.mapboxgl-user-location-dot-stale::after {
    display: none;
}

.mapboxgl-user-location-accuracy-circle {
    background-color: #1da1f233;
    width: 1px;
    height: 1px;
    border-radius: 100%;
}

.mapboxgl-crosshair,
.mapboxgl-crosshair .mapboxgl-interactive,
.mapboxgl-crosshair .mapboxgl-interactive:active {
    cursor: crosshair;
}

.mapboxgl-boxzoom {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: #fff;
    border: 2px dotted #202020;
    opacity: 0.5;
}

@media print {
    /* stylelint-disable-next-line selector-class-pattern */
    .mapbox-improve-map {
        display: none;
    }
}

.mapboxgl-touch-pan-blocker,
.mapboxgl-scroll-zoom-blocker {
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    justify-content: center;
    text-align: center;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 70%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.75s ease-in-out;
    transition-delay: 1s;
}

.mapboxgl-touch-pan-blocker-show,
.mapboxgl-scroll-zoom-blocker-show {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
}

.mapboxgl-canvas-container.mapboxgl-touch-pan-blocker-override.mapboxgl-scrollable-page,
.mapboxgl-canvas-container.mapboxgl-touch-pan-blocker-override.mapboxgl-scrollable-page .mapboxgl-canvas {
    touch-action: pan-x pan-y;
}
